.inputArea {

    width: 330px;
    height: 50px;
    min-height: 50px;
    display: flex;
    flex-direction: row;
    border-style: solid;
    border-color: #c1c1c1;
    border-width: 1px;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: 15px;
    
    }

.input {
  flex: 1;
  font-size: 16px;
  padding-left: 2;
  padding-right: 2;
  margin-left: 10px;
  color: #ffffff;
  background: var(--backgroundColor);
  border: none;
  outline: none;
}
.icon {
    color: #c1c1c1;
}