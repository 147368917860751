.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
}

.modalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    opacity: 0.9;
    background-color: black;
    border-style: solid;
    border-color:var(--primaryColor);
    border-width: 1px;
    border-radius: 10px;
    width: 320px;
}

.titleContainer {
    
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 30px;
}
.close {
    margin-right: 5px;
    color: var(--primaryColor);
}
.title {
    color: white;
}
.content {
    display: flex;
    flex-direction: column;
}

.nome {
    font-size: 16px;
    max-width: 280px;
    font-weight: normal;
    margin-bottom: 20px;
    color: var(--primaryColor);
    
}

.socialArea {
   width: 280px;
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   align-items: center;
   
}

.socialButton {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items:center;
    
 }

.socialIcon {
    margin-bottom: 10px;
    color: var(--primaryColor);
}

.socialNome {
   font-weight: bold;
   font-size: 14px;
   color: var(--primaryColor);
   margin-bottom: 20px;
}