.container {
       
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    padding-right: 5px;
    padding-left: 5px;
    background-color: var(--primaryColor);;
    border-radius: 20px;
    border-width: 1px;
    border-color: #545454;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
 }

  .districtNameText {
   font-size: 18px;
   color: white;
   
}

.icon {
    color: white;
}
