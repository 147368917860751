.container {
    display: flex;
    background-color: var(--backgroundColor);
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    padding-left: 20px;
    padding-right: 20px;
    
}

.sloganText {
    margin-top: 10px;
    font-weight: bold;
    font-size: 16px;
    color: var(--primaryColor);
}

.avisoText {
    margin-top: 10px;
    font-size: 16px;
    color: white;
}

.body {
    width: 350px;
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.aboutIcon {
    color: white;
    position: absolute;
    top: 20px;
    right: 20px;
}
