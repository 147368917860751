.container {
    display: flex;
    background-color: var(--backgroundColor);
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    padding-left: 20px;
    padding-right: 20px;
    
}

.body {
    width: 350px;
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
