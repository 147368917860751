.menuArea {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
 }

.labelText {
    font-size: 16px;
    font-weight: bold;
    color:white;
}

.icon {
    color: white;
}