

.title {
   color: white; 
   font-size: 24px;
   font-weight: bold;
   margin:0;
   margin-top: 10px;
}
.subTitle {
    color: white;
    font-size: 18px;
    font-weight: bold;
    margin:0;
}

.backicon {
    position:absolute;
    left: 20px;
    top: 30px;
    color: white;
    
  }